export const globalConstants = {
  WEBSITE_TITLE: "Sensia",
  CLIENT_URL: process.env.REACT_APP_CLIENT_URL,
  API_URL: process.env.REACT_APP_API_URL,
  RESOURCES_URL: "https://resources.sensia.ai/",
  CONTACT_EMAIL: "support@sensia.ai",
  CONTACT_URL: "https://resources.sensia.ai/contact-us",
  DEFAULT_LANGUAGE: "en",
  FRENCH_LANGUAGE: "fr",
  ENV: process.env.REACT_APP_SENSIA_ENV,
  AVAILABLE_LANGUAGES: ["en"],
};

export const EMOTIONS_COLOR_GREEN = "#27c986";
export const EMOTIONS_COLOR_RED = "#e04f77";
export const EMOTIONS_COLOR_GREY = "#9f99b7";
export const EMOTIONS_COLOR_EMPTY = "#d2d2d2";
export const EMOTIONS_COLOR_WHITE = "#ffffff";
export const COLOR_BLUE_LOADING = "#3a79e6";

export const EMOTIONS_COLORS_ARRAY = {
  admiration: EMOTIONS_COLOR_GREEN,
  anger: EMOTIONS_COLOR_RED,
  annoyance: EMOTIONS_COLOR_RED,
  approval: EMOTIONS_COLOR_GREEN,
  caring: EMOTIONS_COLOR_GREEN,
  confusion: EMOTIONS_COLOR_RED,
  curiosity: EMOTIONS_COLOR_GREEN,
  desire: EMOTIONS_COLOR_GREEN,
  disappointment: EMOTIONS_COLOR_RED,
  disapproval: EMOTIONS_COLOR_RED,
  disgust: EMOTIONS_COLOR_RED,
  embarrassment: EMOTIONS_COLOR_RED,
  excitement: EMOTIONS_COLOR_GREEN,
  fear: EMOTIONS_COLOR_RED,
  gratitude: EMOTIONS_COLOR_GREEN,
  grief: EMOTIONS_COLOR_RED,
  joy: EMOTIONS_COLOR_GREEN,
  love: EMOTIONS_COLOR_GREEN,
  nervousness: EMOTIONS_COLOR_RED,
  optimism: EMOTIONS_COLOR_GREEN,
  pride: EMOTIONS_COLOR_GREEN,
  realization: EMOTIONS_COLOR_GREEN,
  relief: EMOTIONS_COLOR_GREEN,
  remorse: EMOTIONS_COLOR_RED,
  sadness: EMOTIONS_COLOR_RED,
  surprise: EMOTIONS_COLOR_GREEN,
  neutral: EMOTIONS_COLOR_GREY,
};

export const MEDIA_STATUS = {
  TOPROCESS: "TOPROCESS",
  INPROGRESS: "INPROGRESS",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
};

export const PLAN_JSON_LOGIC_KEYS = {
  CUSTOMIZE_INTEGRATED_CLASS: "CUSTOMIZE_INTEGRATED_CLASS",
  CREATE_CUSTOM_CLASS: "CREATE_CUSTOM_CLASS",
  EXPORTS: "EXPORTS",
  MEDIAS_ADD_MEDIA: "MEDIAS_ADD_MEDIA",
  USERS_ADD_USER: "USERS_ADD_USER",
  TEAMS_ADD_TEAM: "TEAMS_ADD_TEAM",
  DOWNGRADE_PLAN_EXPIRATION: "DOWNGRADE_PLAN_EXPIRATION",
  DOWNGRADE_RETROGRADE: "DOWNGRADE_RETROGRADE",
  ENTERPRISE_FEATURE: "ENTERPRISE_FEATURE",
};

export const USER_ROLES_ENUM = {
  ADMIN: "ADMIN",
  USER: "USER",
};
