import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip as BSTooltip } from 'react-bootstrap'

import './tooltip.css';

export const Tooltip = ({ children, content, placement, toolTipTriggerStyle, defaultShow, ...props }) => {
  children = children || null;
  if (!content) {
    return children;
  }  
  return (
    <div className="c-tooltip" {...props}>
      <OverlayTrigger
        placement="bottom"
        flip={true}
        defaultShow={defaultShow}
        overlay={<BSTooltip>{content}</BSTooltip>}
      >
        <div className="c-tooltip__trigger" style={toolTipTriggerStyle}>{children}</div>
      </OverlayTrigger>
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  placement: PropTypes.string,
}

export default Tooltip
