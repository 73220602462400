import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { ROUTES } from "../../app/routing";
import logo from "../../res/images/logo/logo.svg";
import StagingRibbon from "../stagingRibbon/StagingRibbon";
import "./header.css";
import { Link } from "react-router-dom";
import { globalConstants } from "../../app/constants";
import BurgerMenuIcon from "../burgerMenuIcon/BurgerMenuIcon";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileActive: false,
    };
    this.onClickMenuMobile = this.onClickMenuMobile.bind(this);
  }

  onClickMenuMobile() {
    const newActive = !this.state.mobileActive;
    this.setState({
      mobileActive: newActive,
    });
  }

  render() {
    const { t, withoutFake, active } = this.props;
    let classes = ["main-header-showcase"];
    let classesMobile = ["content-menu-mobile", "transition"];
    let classesMaskMobile = ["content-menu-mobile-mask", "transition"];
    if (this.state.mobileActive) {
      classes.push("opened");
      classesMaskMobile.push("opened");
    }
    return (
      <Fragment>
        <div className={classes.join(" ")} data-env={globalConstants.ENV}>
          <StagingRibbon />
          <div className="central-content-1200">
            <div className="position-relative">
              <div className="float-left line-height-83">
                <Link to={ROUTES.HOME} className="link">
                  <img src={logo} alt="Logo" className="website-logo" />
                </Link>
              </div>
              <div className="float-right line-height-83">
                <ul className="menu font-inter-500">
                  <li
                    className={`menu-li-link ${
                      active === "features" ? "active" : ""
                    }`}
                  >
                    <Link to={ROUTES.FEATURES} className=" transition-color">
                      {t("Features")}
                    </Link>
                  </li>
                  <li
                    className={`menu-li-link ${
                      active === "benefits" ? "active" : ""
                    }`}
                  >
                    <Link to={ROUTES.BENEFITS} className=" transition-color">
                      {t("Benefits")}
                    </Link>
                  </li>
                  <li
                    className={`menu-li-link ${
                      active === "pricing" ? "active" : ""
                    }`}
                  >
                    <Link
                      to={ROUTES.PLAN_PRICING}
                      className=" transition-color"
                    >
                      {t("Pricing")}
                    </Link>
                  </li>
                  <li
                    className={`menu-li-link ${
                      active === "resources" ? "active" : ""
                    }`}
                  >
                    <a
                      href={globalConstants.RESOURCES_URL}
                      className="link transition-color"
                    >
                      {t("Resources")}
                    </a>
                  </li>
                  <li className="menu-li-link with-signin">
                    <a href={globalConstants.CLIENT_URL} className="button">
                      {t("Sign in")}
                    </a>
                  </li>
                  <li className="only-mobile">
                    <BurgerMenuIcon onClick={this.onClickMenuMobile} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={classesMaskMobile.join(" ")}>
          <div className="closer" onClick={this.onClickMenuMobile} />
          <div className={classesMobile.join(" ")}>
            <div className="content">
              <ul className="menu font-inter-500">
                <li
                  className={`menu-li-link ${
                    active === "features" ? "active" : ""
                  }`}
                >
                  <Link to={ROUTES.FEATURES} className="link transition-color">
                    {t("Features")}
                  </Link>
                </li>
                <li
                  className={`menu-li-link ${
                    active === "benefits" ? "active" : ""
                  }`}
                >
                  <Link to={ROUTES.BENEFITS} className="link transition-color">
                    {t("Benefits")}
                  </Link>
                </li>
                <li
                  className={`menu-li-link ${
                    active === "pricing" ? "active" : ""
                  }`}
                >
                  <Link
                    to={ROUTES.PLAN_PRICING}
                    className="link transition-color"
                  >
                    {t("Pricing")}
                  </Link>
                </li>
                <li
                  className={`menu-li-link ${
                    active === "resources" ? "active" : ""
                  }`}
                >
                  <a
                    href={globalConstants.RESOURCES_URL}
                    className="link transition-color"
                  >
                    {t("Resources")}
                  </a>
                </li>
                <li className="menu-li-link">
                  <a href={globalConstants.CLIENT_URL} className="button">
                    {t("Sign in")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {!withoutFake && <div className="fake-main-header-showcase" />}
      </Fragment>
    );
  }
}

export default withTranslation()(Header);
