import React from 'react';
import { useToasts } from 'react-cooked-bread';


const ToastedComponent = ({ component: Component, ...rest }) => {
    const toastFuncs = useToasts()
    return (
        <Component {...rest} {...toastFuncs} />
    );
  };

export default ToastedComponent;