
import React from 'react';
import './tooltip.css';
import { withTranslation } from "react-i18next";


class ErrorTooltipContent extends React.Component {
  render() {
    return (
      <div className="color-e04f77">
        {this.props.error}
      </div>
    );
  }
}

export default withTranslation()(ErrorTooltipContent);