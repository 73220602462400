import React from "react";

import './Score.css'
import StringHelper from "../../app/helpers/StringHelper";
import EmotionHelper from "../../app/helpers/EmotionHelper";

import image from '../../res/images/last-review.svg'
import ProjectRoundedEmotion from "../../views/projectWorkspace/elements/ProjectRoundedEmotion";
import moment from "moment";

class Score extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        const { style, t, sourceTooltip } = this.props
        return (
            <div className={this.props?.size} id="scores" style={style}>
                {!this.props?.listItem && <div id="sources" className="number-box">
                    <p className={this.props?.size}>{`Source${this.props.sources > 1 ? "s" : ""}`}</p>
                    <h3 className={this.props?.size}>{this.props.sources || "-"}</h3>

                    <div id="source-tooltip">
                        <div id="cachetop"></div>
                        {sourceTooltip !== undefined && <div className="content">
                            {sourceTooltip.map((elm, i) => {
                                // const lang = []
                                // elm.languages.forEach(langItem => {
                                //     lang.push(langItem.label)
                                // });
                                return (
                                    <div className="box">
                                        <div className="item">
                                            <img src={process.env.REACT_APP_API_URL + elm.icon} alt="datasource's logo" height="18px"/>
                                            <div className="item-text">
                                                <p className="title">{elm.label}</p>
                                                {/* <p>{lang.join(', ')}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                        <div id="cachebottom"></div>
                    </div>
                </div>}
                {!this.props?.listItem && <div className="separator"></div>}

                <div className="number-box">
                    <p className={this.props?.size}>Reviews</p>
                    <h3 className={this.props?.size}>{this.props.total_reviews || "-"}</h3>
                </div>

                <div id="reviews" className="number-box">
                    <p className={this.props?.size}>Relevant reviews</p>
                    <h3 className={this.props?.size}>{this.props.relevantReviews || "-"} {this.props.relevantReviews === "-" ? "" : <div className={`${this.props?.size} progress-bar`}><div className="progress-bar-content" style={{ width: `${this.props.relevantReviews}` }}></div></div>}</h3>

                    <div id="relevant-tooltip">
                        <div id="cachetop"></div>
                        <div className="content">
                            <div className="top-infos">
                                <div className="relevant-title">
                                    <h3 className={this.props?.size}>{this.props.reviews || "-"}</h3>
                                    <p>out of {this.props.total_reviews || "-"}</p>
                                </div>
                                <h3 className={this.props?.size}>{this.props.relevantReviews || "-"}</h3>
                            </div>
                            <div className="progress-bar">
                                <div className="progress-bar-content" style={{ width: `${this.props.relevantReviews}` }}></div>
                            </div>
                            <p>relevants reviews</p>
                            <div className="last-review">
                                <img src={image} alt="" />
                                <p>Last review: {moment.utc(this.props.updated_at).local().fromNow(true)}</p>
                            </div>
                        </div>
                        <div id="cachebottom"></div>
                    </div>
                </div>

                <div className="separator"></div>

                <div className="number-box">
                    <p className={this.props?.size}>Positive</p>
                    <h3 className={`${this.props.positive === null || this.props.positive === undefined ? "" : "positive"} ${this.props?.size}`}>{this.props.positive || "-"}</h3>
                </div>

                <div className="number-box">
                    <p className={this.props?.size}>Negative</p>
                    <h3 className={`${this.props.negative === null || this.props.negative === undefined ? "" : "negative"} ${this.props?.size}`}>{this.props.negative || "-"}</h3>
                </div>

                <div className="separator"></div>

                <div className="number-box">
                    <p className={this.props?.size}>Score</p>
                    <h3 className={this.props?.size}>{typeof (this.props.score) !== "number" ? "-" : this.props.score === "-" ? "-" : Math.round(this.props.score * 100) / 100}</h3>
                </div>

                <div className="separator"></div>

                <div className="feeling-box feeling">
                    <p className={this.props?.size}>Top feeling</p>
                    {(this.props?.listItem || this.props?.media) && !this.props.print && <div>
                        <div id="color-dot" style={{ backgroundColor: EmotionHelper.getEmotionColor(this.props.feeling), width: "8px", height: "8px", borderRadius: "100%" }} />
                        <h3 className={this.props?.size} id="feeling">{StringHelper.capitalizeFirstLetter(this.props.feeling || "-")}</h3>
                    </div>}
                    
                    {!this.props?.listItem && !this.props?.media && <ProjectRoundedEmotion
                        allEmotions={this.props.feeling}
                        withTooltip={true}
                        projectId={this.props.projectId}
                        datasourceId={this.props.datasourceId} />}
                    
                        {this.props?.print && <ProjectRoundedEmotion
                            allEmotions={this.props.feeling}
                            withTooltip={true}
                            projectId={this.props.projectId}
                            datasourceId={this.props.datasourceId} />}
                    {!this.props.feeling && <h3 className={this.props?.size} id="feeling">{"-"}</h3>}
                </div>
            </div>
        )
    }
}

export default Score