import React from "react";
import { withTranslation } from "react-i18next";
import { globalConstants } from "../../app/constants";

import Check from "../../res/images/icons/check.svg";
import "./planPricingBox.css";
import HttpHelper from "../../app/helpers/HttpHelper";

function PlanPricingBox(props) {
  const {
    t,
    title,
    planType,
    monthlyPrice,
    yearly_price,
    subtitle,
    subprice,
    subpriceYearly,
    advantages,
    recommended,
    yearly,
    onClick,
    currency_code,
  } = props;
  const classes = ["content-text", "content-pricing-box"];
  if (recommended) {
    classes.push("recommended");
  }
  let displayPrice = "";
  let displaySubPrice = "";

  const language = navigator.language;

  if (planType === "Enterprise") {
    displaySubPrice = <p className="sub-price">{subprice}</p>;
    displayPrice = <p className="price customPlan">{t("Custom plan")}</p>;
  } else if (planType === "Free") {
    displaySubPrice = <p className="sub-price sub-price-free">{subprice}</p>;
    displayPrice = (
      <p className="price">
        <span className="min">€</span>
        <span className="maj">0/</span>
        <span className="min">{t("forever")}</span>
      </p>
    );
  } else if (yearly) {
    displayPrice = (
      <div className="price">
        <span className="maj">
          {new Intl.NumberFormat(language, {
            style: "currency",
            currency: currency_code,
            minimumFractionDigits: 0,
          }).format(yearly_price.toFixed())}
          /
        </span>
        <span className="min">{t("User/Year")}</span>
      </div>
    );
    displaySubPrice = (
      <p className="sub-price">
        {new Intl.NumberFormat(language, {
          style: "currency",
          currency: currency_code,
          minimumFractionDigits: 0,
        }).format(monthlyPrice.toFixed())}
        {subpriceYearly}
      </p>
    );
  } else {
    displayPrice = (
      <div className="price">
        <span className="min"></span>
        <span className="maj">
          {new Intl.NumberFormat(language, {
            style: "currency",
            currency: currency_code,
            minimumFractionDigits: 0,
          }).format(monthlyPrice.toFixed())}
          /
        </span>
        <span className="min">{t("User/Month")}</span>
      </div>
    );

    displaySubPrice = (
      <p className="sub-price">
        {t("or ")}
        {new Intl.NumberFormat(language, {
          style: "currency",
          currency: currency_code,
          minimumFractionDigits: 0,
        }).format(yearly_price.toFixed())}
        {subprice}
      </p>
    );
  }

  const baseButtonUrl = `${globalConstants.CLIENT_URL}/login`;
  const params = yearly
    ? { plan: planType, freq: "yearly" }
    : { plan: planType };
  const buttonUrl =
    planType === "Free" || planType === "Enterprise"
      ? baseButtonUrl
      : HttpHelper.addParametersToUrl(baseButtonUrl, params);

  return (
    <div className="column-percent-25">
      <div className={classes.join(" ")}>
        <div className="tag-recommended">
          <div>{t("Recommended")}</div>
        </div>
        <p className="title">{title}</p>
        <p className="subtitle">{subtitle}</p>
        {displayPrice}
        {displaySubPrice}
        <div className="content-advantages">
          {advantages
            ? advantages.map((element, y) => {
                return (
                  <div
                    key={element.title + y}
                    className="advantages-box padding-bottom-20"
                  >
                    <p className="title">{element.title}</p>
                    {element.advantage.map((adv, i) => {
                      return (
                        <div key={adv + i} className="raw-advantage">
                          <img className="icon-check" src={Check} alt="" />
                          <p className="advantage-lst">{adv}</p>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            : null}
        </div>
        <div className="btn-position">
          {typeof onClick !== "undefined" ? (
            <button
              onClick={onClick}
              className="button backgroundless padding-0 contact-sales"
            >
              {t("Contact sales")}
            </button>
          ) : (
            <a
              href={buttonUrl}
              target="_blank"
              rel="noreferrer"
              className="button backgroundless padding-0"
            >
              {t("Get started")}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

PlanPricingBox.defaultProps = {
  recommended: false,
};

export default withTranslation()(PlanPricingBox);
