import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";

import SwitchPricing from "../switchPricing/SwitchPricing";

// IMG IMPORT
import Arrow from "../../res/images/icons/path-4.svg";
// CSS
import "./planPricingBody.css";
import PlanPricingBox from "./PlanPricingBox";

class PlanPricingBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      yearly: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onChange = () => {
    this.setState({
      yearly: !this.state.yearly,
    });
  };

  onClick = () => {
    window.open("https://sensia.ai/contact-sales", "_self");
  };

  render() {
    const { t, item_list } = this.props;
    let proAdvantage = {};
    let businessAdvantage = {};
    let freeAdvantage = {};
    let entrepriseAdvantage = {};

    if (item_list) {
      for (const item of item_list) {
        if (item.level === "PRO") {
          proAdvantage = {
            title: t("PRO"),
            planType: "Pro",
            monthlyPrice: item.monthly_price,
            currency_code: item.currency_code,
            yearly_price: item.yearly_price,
            price: item.monthly_price,
            subtitle: t("For individuals"),
            subprice: t(" when billed yearly"),
            subpriceYearly: t(" when billed monthly"),
            freeCredit: 1000,
            advantages: [
              {
                title: t("With Pro plan, you can get"),
                advantage: [
                  t("Unlimited Project"),
                  t("All Features"),
                  t("1 AI Category "),
                  t("1 AI Customization"),
                  t("Export & Sharing"),
                ],
              },
              {
                title: t("Review collection"),
                advantage: [
                  t("Amazon, Youtube, G shopping"),
                  t("> 100 languages"),
                ],
              },
              {
                title: t("Review storage"),
                advantage: [
                  t("1 000 included"),
                  t("Additional Storage (in option)"),
                ],
              },
              {
                title: t("Support"),
                advantage: [t("Chat")],
              },
            ],
          };
        }
        if (item.level === "BUSINESS") {
          businessAdvantage = {
            title: t("BUSINESS"),
            planType: "Business",
            monthlyPrice: item.monthly_price,
            yearly_price: item.yearly_price,
            currency_code: item.currency_code,
            price: item.monthly_price,
            subtitle: t("For small brand"),
            subprice: t(" when billed yearly"),
            subpriceYearly: t(" when billed monthly"),
            freeCredit: 5000,
            rawClass: [],
            advantages: [
              {
                title: t("With Business plan, you can get"),
                advantage: [
                  t("Unlimited Project"),
                  t("All Features"),
                  t("1 AI Category "),
                  t("Additional Category (in option)"),
                  t("4 AI Customization"),
                  t("Export & Sharing"),
                ],
              },
              {
                title: t("Review collection"),
                advantage: [t("All"), t("> 100 languages")],
              },
              {
                title: t("Review storage"),
                advantage: [
                  t("2 400 included"),
                  t("Additional Storage (in option)"),
                ],
              },
              {
                title: t("Support"),
                advantage: [t("Chat")],
              },
            ],
          };
        }
        entrepriseAdvantage = {
          title: t("ENTERPRISE"),
          planType: "Enterprise",
          monthlyPrice: null,
          subtitle: t("For compagnies"),
          subprice: t("Per-user billing & data volume"),
          subpriceYearly: t("Per-user billing & data volume"),
          freeCredit: 20000,
          rawClass: [],
          advantages: [
            {
              title: t("With Enterprise plan, you can get"),
              advantage: [
                t("Unlimited Project"),
                t("All Features"),
                t("1 AI Category "),
                t("Additional Category (in option)"),
                t("Unlimited AI Customization"),
                t("Bulk Import"),
                t("Export & Sharing"),
              ],
            },
            {
              title: t("Review collection"),
              advantage: [t("All + Custom Connector"), t("> 100 languages")],
            },
            {
              title: t("Review storage"),
              advantage: [
                t("Custom Storage"),
                t("Additional Storage (in option)"),
              ],
            },
            {
              title: t("Support"),
              advantage: [t("Premium")],
            },
          ],
        };
        freeAdvantage = {
          title: t("FREE"),
          planType: "Free",
          monthlyPrice: 0,
          subpriceYearly: "",
          subtitle: "",
          price: "0",
          subprice: t("Good to start and experiment"),
          advantages: [
            {
              title: t("With Free plan, you can get"),
              advantage: [
                t("Unlimited Project"),
                t("All Features"),
                t("1 AI Category "),
              ],
            },
            {
              title: t("Review collection"),
              advantage: [t("Amazon, Youtube"), t("> 100 languages")],
            },
            {
              title: t("Review storage"),
              advantage: [t("500 included")],
            },
          ],
        };
      }
    }

    return (
      <Fragment>
        <div className="plan-pricing-showcase central-content-1260">
          <h1 className="title">{t("Plans & pricing")}</h1>
          <p className="sub-title">
            {t("Sign up for free. Upgrade as you grow.")}
          </p>
          <div className="switch-btn">
            <SwitchPricing onClick={this.onChange} yearly={this.state.yearly} />
          </div>
          <div className="vingt-off">
            <p className="save-purcent">{t("Save 20%")}</p>
            <img className="icon-arrow" src={Arrow} alt="" />
          </div>
          <div className="content-4-columns-centered box position-relative">
            <div className="content-column no-hidden container-pricing-box">
              <PlanPricingBox
                planType={freeAdvantage.planType}
                title={freeAdvantage.title}
                price={freeAdvantage.price}
                subprice={freeAdvantage.subprice}
                advantages={freeAdvantage.advantages}
                yearly={this.state.yearly}
                monthlyPrice={freeAdvantage.monthlyPrice}
                subpriceYearly={freeAdvantage.subpriceYearly}
              />
              <PlanPricingBox
                planType={proAdvantage.planType}
                title={proAdvantage.title}
                subtitle={proAdvantage.subtitle}
                price={proAdvantage.price}
                subprice={proAdvantage.subprice}
                advantages={proAdvantage.advantages}
                recommended={true}
                yearly={this.state.yearly}
                monthlyPrice={proAdvantage.monthlyPrice}
                yearly_price={proAdvantage.yearly_price}
                subpriceYearly={proAdvantage.subpriceYearly}
                currency_code={proAdvantage.currency_code}
              />
              <PlanPricingBox
                planType={businessAdvantage.planType}
                title={businessAdvantage.title}
                subtitle={businessAdvantage.subtitle}
                price={businessAdvantage.price}
                subprice={businessAdvantage.subprice}
                advantages={businessAdvantage.advantages}
                yearly={this.state.yearly}
                monthlyPrice={businessAdvantage.monthlyPrice}
                yearly_price={businessAdvantage.yearly_price}
                subpriceYearly={businessAdvantage.subpriceYearly}
                currency_code={businessAdvantage.currency_code}
              />
              <PlanPricingBox
                planType={entrepriseAdvantage.planType}
                title={entrepriseAdvantage.title}
                subtitle={entrepriseAdvantage.subtitle}
                price={entrepriseAdvantage.price}
                subprice={entrepriseAdvantage.subprice}
                advantages={entrepriseAdvantage.advantages}
                yearly={this.state.yearly}
                onClick={this.onClick}
                monthlyPrice={entrepriseAdvantage.monthlyPrice}
                subpriceYearly={entrepriseAdvantage.subpriceYearly}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(PlanPricingBody);
