import React, { Fragment } from "react";
import { ROUTES } from "../app/routing";

class Home extends React.Component {
  constructor(props) {
    super(props);

    window.location.replace(ROUTES.HOME_REDIRECT);
  }

  render() {
    return null;
  }
}

export default Home;
