import { uniqueId } from 'lodash';
import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { globalConstants } from '../app/constants';

class ConnectivityListener extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOnline: window.navigator.onLine
    }
    this.offlineToastId = null;
    this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    this.doTheMagicTrick = this.doTheMagicTrick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    const isOnline = window.navigator.onLine;
    if(!isOnline){
      this.doTheMagicTrick(isOnline);
    }
  }
  componentWillUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  }


  updateOnlineStatus() {
    const isOnline = window.navigator.onLine;
    this.setState({ isOnline: isOnline });
  }

  doTheMagicTrick(isOnline) {
    const { t } = this.props;
    if (!isOnline && !this.offlineToastId) {
      this.offlineToastId = uniqueId();
    }

    const content = (
      <div>
        <div className="font-inter-600">{isOnline ? t('You are now online!') : t('You are offline...')}</div>
        <div>
          {isOnline
            ? t('{{websiteTitle}} is now operational.', {websiteTitle: globalConstants.WEBSITE_TITLE})
            : t('{{websiteTitle}} may not work well.', {websiteTitle: globalConstants.WEBSITE_TITLE})}
        </div>
      </div>
    );

    const toastOptions = isOnline ? { type: 'info', autoDismiss: true } : { type: 'warning', autoDismiss: false, id: this.offlineToastId }
    this.props.addToast(content, toastOptions);

    if (isOnline && this.offlineToastId) {
      this.props.removeToast(this.offlineToastId);
      this.offlineToastId = null;
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { isOnline } = this.state;

    if (prevState.isOnline !== isOnline) {
      return { isOnline };
    }

    return null;
  }

  componentDidUpdate(props, state, snapshot) {
    if (!snapshot) return;
    const { isOnline } = snapshot;
    this.doTheMagicTrick(isOnline);
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(ConnectivityListener));
