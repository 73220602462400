
import React, { Fragment } from 'react';
import './tooltip.css';
import { withTranslation } from "react-i18next";
import StringHelper from '../../app/helpers/StringHelper';
import EmotionHelper from '../../app/helpers/EmotionHelper';
import CollectionHelper from '../../app/helpers/CollectionHelper';


class EmotionTooltipContent extends React.Component {
  constructor(props) {
    super(props);

    this.getBarStyle = this.getBarStyle.bind(this);
    this.getProperData = this.getProperData.bind(this);
    this.getGlobalEmotion = this.getGlobalEmotion.bind(this);
  }

  getBarStyle(maxPercent, currentPercent, libelle) {
    const width = (currentPercent * 100) / maxPercent;
    return {
      width: width + '%',
      backgroundColor: EmotionHelper.getEmotionColor(libelle)
    }
  }

  getGlobalEmotion(global_emotions, properData, maxPercent, sum) {
    global_emotions.forEach((element, i) => {
      const nbOccurences = (element.hasOwnProperty('occurence')) ? element.occurence : element.occ;
      const roundedPercent = Math.round(parseFloat((nbOccurences / sum * 100).toFixed(2)));
      if (maxPercent < roundedPercent) {
        maxPercent = roundedPercent;
      }
    });
    global_emotions.forEach((element, i) => {
      let newLine = {};
      const nbOccurences = (element.hasOwnProperty('occurence')) ? element.occurence : element.occ;
      const label = (element.hasOwnProperty('_id')) ? element._id : element.emotion;
      const libelle = StringHelper.capitalizeFirstLetter(label);
      const roundedPercent = Math.round(parseFloat((nbOccurences / sum * 100).toFixed(2)));
      newLine.libelle = libelle;
      newLine.roundedPercent = roundedPercent;
      newLine.sort = nbOccurences;
      newLine.barStyle = this.getBarStyle(maxPercent, roundedPercent, label);
      properData.push(newLine);
    });
    return properData
  }

  getProperData() {
    const { data } = this.props;
    let properData = [];
    let maxPercent = 0;
    let global_emotions = [];
    let global_emotions_object = Object.assign({}, data);
    if (Object.keys(global_emotions_object).length > 0) {
      let sum = 1;
      for (const [key, value] of Object.entries(global_emotions_object)) {
        global_emotions.push({
          '_id': key,
          'occurence': value
        });
      }
      if (global_emotions.length > 0) {
        sum = (global_emotions[0].hasOwnProperty('occurence')) ?
          global_emotions.map(d => d.occurence).reduce((a, b) => a + b) :
          global_emotions.map(d => d.occ).reduce((a, b) => a + b);
      }
      properData = this.getGlobalEmotion(global_emotions, properData, maxPercent, sum)
    }
    properData = CollectionHelper.sortBy(properData, 'sort', 'desc');
    properData = properData.slice(0, 10).filter(d => d.roundedPercent > 0);
    return properData;
  }

  render() {
    const properData = this.getProperData();
    const hasSeparator = properData.length > 1;

    if (properData.length === 0) {
      return null;
    }
    return (
      <div>
        <div className="base-tooltip">
          <div className="tooltip-content">
            {properData.map((item, i) => {
              return (
                <Fragment key={`line-emotion-${i}`}>
                  <div className="line-emotion">
                    <div className="content-bar"><div className="bar" style={item.barStyle}></div></div>
                    <div className="content-percent text-align-right font-size-13 font-inter-500 color-969696">{item.roundedPercent}%</div>
                    <div className="content-libelle text-align-left font-size-15 font-inter-400 color-000000">{item.libelle}</div>
                  </div>
                  {i === 0 && hasSeparator && (<div className="tooltip-separator" />)}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

EmotionTooltipContent.defaultProps = {

}

export default withTranslation()(EmotionTooltipContent);