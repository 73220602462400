import React, { Fragment } from 'react';
import './listOptSelect.css';
import { withTranslation } from "react-i18next";

class ListOptSelect extends React.Component {
    constructor(props) {
        super(props);

        const activeLabel = this.getCurrentActiveLabel(props.displayFamily, props.defaultActive, props.lists);
        this.state = {
            isOpened: false,
            currentActiveLabel: activeLabel,
            lists: props.lists,
        };
        this.wrapperRef = React.createRef();
        this.onToggleOpen = this.onToggleOpen.bind(this);
        this.onClickOutside = this.onClickOutside.bind(this);
        this.onClickItem = this.onClickItem.bind(this);
        this.getCurrentValues = this.getCurrentValues.bind(this);
        this.getCurrentActiveLabel = this.getCurrentActiveLabel.bind(this);
        this.hasOneValueActive = this.hasOneValueActive.bind(this);
        this.ListOptSelect = this.ListOptSelect.bind(this);
        this.listContentHandler = this.listContentHandler.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.onClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (this.props.autoRefresh && (prevProps.defaultActive !== this.props.defaultActive || JSON.stringify(prevProps.lists) !== JSON.stringify(this.props.lists))){
            const activeLabel = this.getCurrentActiveLabel(this.props.displayFamily, this.props.defaultActive, this.props.lists);
            this.setState({
                currentActiveLabel: activeLabel,
                lists: this.props.lists
            })
        }
    }

    getCurrentActiveLabel(displayFamily, defaultActive, lists) {
        let ret = null;
        for (const [family, list] of Object.entries(lists)) {
            list.map((item, j) => {
                if (family == displayFamily && item.active) {
                    ret = item.activeLabel;
                }
            })
        }
        if(!ret){
            for (const [family, list] of Object.entries(lists)) {
                list.map((item, j) => {
                    if (family == displayFamily && item.value == defaultActive) {
                        ret = item.activeLabel;
                    }
                })
            }
        }
        return ret;
        
    }

    onToggleOpen() {
        this.setState({ isOpened: !this.state.isOpened });
    }

    onClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpened: false });
        }
    }

    getCurrentValues() {
        const lists = this.state.lists;
        let ret = {};
        for (const [family, list] of Object.entries(lists)) {
            const hasOneActive = this.hasOneValueActive(family);
            ret[family] = (list.type && list.type === 'intboolean') ? 0 : null;
            for (const item of list) {
                if (item.active || (!hasOneActive && item.value === this.props.defaultActive)) {
                    ret[family] = item.value;
                }
            }
        }
        return ret;
    }

    hasOneValueActive(targetFamily) {
        const lists = this.state.lists;
        let hasOneActive = false;
        for (const [family, list] of Object.entries(lists)) {
            for (const item of list) {
                if(targetFamily){
                    if(targetFamily === family && item.active){
                        hasOneActive = true;
                    }
                } else if (item.active) {
                    hasOneActive = true;
                }
            }
        }
        return hasOneActive;
    }

    onClickItem(family, item) {
        let newLists = Object.assign({}, this.state.lists);
        if (family in newLists) {
            let list = newLists[family];
            let newValue = !item.active;
            list.forEach((element, index) => {
                if (item.value === element.value) {
                    element.active = newValue;
                } else {
                    element.active = false
                }
                // defaultActive case
                if(!newValue && element.value === this.props.defaultActive){
                    element.active = true;
                }
                newLists[family][index] = element;
            });
        }
        const newCurrentActiveLabel = this.getCurrentActiveLabel(this.props.displayFamily, this.props.defaultActive, newLists);
        this.setState({ lists: newLists,  currentActiveLabel: newCurrentActiveLabel, isOpened: false });
        if(this.props.onChange){
            this.props.onChange(this.getCurrentValues());
        }
    }

    listContentHandler(key, i, list, hasOneActive) {
        let tmp = []
        tmp.push(
            <Fragment key={`listOptSelect-fragment-${key}`}>
                {(i !== 0) && <div className="separator" />}
                <ul key={`listOptSelect-ul-${key}`}>
                    {list.map((item, j) => {
                        let itemClasses = ["transition-color", "text-ellipsis"];
                        if (item.active || (!hasOneActive && item.value === this.props.defaultActive)) {
                            itemClasses.push("active");
                        }
                        return (<li key={`listOptSelect-li-${j}`} className={itemClasses.join(' ')} onClick={() => { this.onClickItem(key, item) }}>{item.label}</li>);
                    })}
                </ul>
            </Fragment>
        );
        return tmp
    }

    ListOptSelect() {
        const lists = this.state.lists;
        let listContent = [];
        let i = 0;
        for (const [key, list] of Object.entries(lists)) {
            const hasOneActive = this.hasOneValueActive(key)
            if(key === 'top_message'){
                listContent.push(
                    <Fragment key={`listOptSelect-fragment-${key}`}>
                        {(i !== 0) && <div className="separator" />}
                        <ul key={`listOptSelect-ul-${key}`}>
                            {list.map((item, j) => {
                                let itemClasses = ["transition-color", "text-ellipsis", 'message'];
                                if (item.active || (!hasOneActive && item.value === this.props.defaultActive)) {
                                    itemClasses.push("active");
                                }
                                return (<li key={`listOptSelect-li-${j}`} className={itemClasses.join(' ')}>{item.label}</li>);
                            })}
                        </ul>
                    </Fragment>
                );
            }else{
                listContent.push(this.listContentHandler(key, i, list, hasOneActive))
            }
            i++;
        }
        return listContent
    }

    render() {
        let fullContentClasses = ["full-content-list-opt-select"];
        if(this.props.position){
            fullContentClasses.push(`position-${this.props.position}`)
        }
        if(this.props.customClass){
            fullContentClasses.push(this.props.customClass)
        }
        if (this.state.isOpened) {
            fullContentClasses.push('opened');
        }
        const listContent = this.ListOptSelect()
        
        return (
            <div ref={this.wrapperRef} className={fullContentClasses.join(' ')}>
                <div className="position-relative">
                    { this.props.selectorName && 
                        <p className='selector-label-name'>{this.props.selectorName}</p>
                    }
                    <div className={`content transition-color color-${this.props.color}`} onClick={this.onToggleOpen}>
                        <span className={`current-value color-${this.props.color}`}>{this.state.currentActiveLabel}</span>
                    </div>
                </div>
                <div className="list transition-opacity">
                    {listContent}
                </div>
            </div>
        );
    }
}

export default withTranslation()(ListOptSelect);