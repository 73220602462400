import React from 'react';
import './LoadingView.css';

import Loader from '../../components/loader/Loader';
import {Helmet} from "react-helmet";


class LoadingView extends React.Component {

    render() {
        return (
            <div className="content-loading-view">
                <Helmet>
                    <title>{this.props.title}</title>
                </Helmet>
                <Loader />
            </div>
        );
    }
}

export default LoadingView;