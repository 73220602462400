
import React from 'react';
import './tooltip.css';
import { withTranslation } from "react-i18next";
import Loader from '../loader/Loader';


class LoadingTooltipContent extends React.Component {
  render() {
    return (
      <div className="text-align-center">
        <Loader />
      </div>
    );
  }
}

export default withTranslation()(LoadingTooltipContent);