import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './res/style/hush/hush.css';
import './res/style/hush/puppy.css';
import './res/style/style.css';

// import i18n (needs to be bundled ;))
import './app/i18n';

import App from './app/App';
import {store} from './app/helpers';


ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root'));
