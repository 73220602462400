import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import parse from "html-react-parser";

import { globalConstants } from "../../app/constants";
import LoadingView from "../loading/LoadingView";
import HttpHelper from "../../app/helpers/HttpHelper";

import Score from "../../components/score/Score";

import "./OverView.css";
import "./style.css";

// images
import sensiaLogo from "../../res/images/SensiaLogo.svg";
import { ROUTES } from "../../app/routing";
import ListOptSelect from "../../components/listOptSelect/ListOptSelect";
import NotFoundView from "../errors/NotFoundView";

class ProjectDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: null,
      project: null,
      displayNotFound: false,
      isLoaded: false,
      formData: {
        page: 1,
        page_size: 20,
      },
      datasourceId: null,
      print: false,
      media: false,
      mediaChatGpt: {},
      mediaStats: {},
      chatGpt: {},
      score: {},
    };

    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
    this.pageTitleHandler = this.pageTitleHandler.bind(this);

    this.fetchInitial = this.fetchInitial.bind(this);
    this.fetchMediaList = this.fetchMediaList.bind(this);
    this.getMediaScopeList = this.getMediaScopeList.bind(this);
    this.onChangeMediaScope = this.onChangeMediaScope.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    window.addEventListener("scroll", () => {
      this.handleScroll();
    });
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
    window.removeEventListener("scroll", () => {
      this.handleScroll();
    });
  }

  displayToastErrorMessage(error, onThen, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (typeof onThen == "function") {
      onThen();
    }
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  async fetchInitial(first) {
    const url = `${globalConstants.API_URL}`;
    const hash = document.location.pathname.split("/")[2];

    const finalUrl = url + ROUTES.PROJECT_WS + hash;

    fetch(finalUrl)
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              ok: response.ok,
              data: json,
            })
          )
        );
      })
      .then(({ status, data, ok }) => {
        if (!ok) {
          console.log("Error fetching project data, response status :", status);

          if (status == 400) {
            this.setState({
              isLoaded: true,
              displayNotFound: true,
            });
          }
          return;
        }

        this.setState({
          project: data,
          chatGpt: {
            reports: data.reports,
          },
          score: {
            rewiews: data.statistics.percent_data.total_obs_used,
            total_obs: data.statistics.percent_data.total_obs,
            total_obs_used: data.statistics.percent_data.total_obs_used,
            pos: data.statistics.percent_data.percent_pos,
            neg: data.statistics.percent_data.percent_neg,
            score: data.statistics.product_score,
            feeling: data.statistics.global_emotions,
            updated_at: data.updated_at,
          },
          projectId: data.id,
          updated_at: data.updated_at,
        });

        if (data.medias > 0) {
          this.fetchMediaList({}, false, first);
        } else {
          this.setState({
            isLoaded: true,
          });
        }
      })
      .catch(function (error) {
        console.error("request failed: ", error);
      });
  }

  fetchMediaList(data, change_scope = false, first = true) {
    const url = `${globalConstants.API_URL}`;
    const finalUrl = url + ROUTES.MEDIA_WS + this.state.project.id + "/media";

    fetch(finalUrl)
      .then((response) => response.json())
      .then((response) => {
        let mediaChatGptList = {};
        let mediaStatsList = {};

        const sources = response.data
          .filter((elm) => elm.status !== "FAILED")
          .filter(
            (elm) =>
              elm.reports
          );
        let nbSources = 0;
        if (sources) {
          nbSources = sources.length;
        }

        sources.forEach((elm) => {
          mediaChatGptList[elm.id] = {
            updated_at: elm.updated_at,
            reports: elm.reports,
          };

          mediaStatsList[elm.id] = {
            source: 1,
            rewiews: elm.total_obs.total_obs_used,
            total_obs: elm.total_obs.total_obs,
            total_obs_used: elm.total_obs.total_obs_used,
            pos: elm.percent_pos,
            neg: elm.percent_neg,
            score: elm.average_score,
            feeling: elm.main_emotion,
            updated_at: elm.updated_at,
          };
        });

        this.setState({
          totalItems: nbSources,
          mediaChatGpt: mediaChatGptList,
          mediaStats: mediaStatsList,
          isLoaded: true,
          datasourcesList: sources,
        });

        // if (!change_scope && first && (this.state.datasourceId !== null || this.state.datasourceId !== undefined)) {
        //     this.onChangeMediaScope({ datasource_id: this.state.datasourceId })
        // }
      })
      .catch(function (error) {
        console.error("request failed: ", error);
      });
  }

  handleErrorData(error) {
    console.log(error);
  }

  pageTitleHandler() {
    return `${this.state.project ? this.state.project.name + " -" : ""} ${
      globalConstants.WEBSITE_TITLE
    }`;
  }

  selectAnchor(_anchor) {
    if (this.state.isLoaded && this.state.totalItems > 0) {
      document.querySelectorAll(".anchor").forEach((anchor) => {
        anchor?.classList.remove("active");
      });
      const menu_div = document.getElementById(`menu_${_anchor}`);
      menu_div?.classList.add("active");
      const selectedAnchor = document.getElementById(_anchor);
      const selectedAnchorPos = selectedAnchor.offsetTop;

      window.scrollTo({ top: selectedAnchorPos - 15, behavior: "smooth" });
    }
  }

  handleScroll() {
    const mainContent = document.getElementById("main-content");

    if (
      this.state.isLoaded &&
      this.state.totalItems > 0 &&
      mainContent !== null &&
      mainContent !== undefined
    ) {
      let scrollValue = window.scrollY;
      let mainContentPos = mainContent.offsetTop;


      let activeSection = null;
      let prviousPos = mainContentPos - 50;
      document.querySelectorAll(".report-section").forEach((section) => {
        const sectionPosition = section.offsetHeight + prviousPos;
        prviousPos += section.offsetHeight;

        if (!activeSection && scrollValue < sectionPosition) {
          activeSection = section;
        }
      });

      document.querySelectorAll(".anchor").forEach((anchor) => {
        anchor?.classList.remove("active");
      });

      const menu_div = document.getElementById(`menu_${activeSection.id}`);
      menu_div?.classList.add("active");
    }
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  getMediaScopeList() {
    const { t } = this.props;
    let ret = {};

    const currentDatasourceIdValue = this.state.datasourceId;
    ret.top_message = [
      {
        label: t("Choose insight scope"),
      },
    ];
    ret.datasource_id = [
      {
        label: t("All data sources"),
        value: null,
        active: currentDatasourceIdValue == null,
        activeLabel: t("All data sources"),
      },
    ];
    const datasourcesList = this.state.datasourcesList;

    datasourcesList.forEach((element) => {
      ret.datasource_id.push({
        label: element.title,
        value: element.id,
        active: currentDatasourceIdValue === element.id,
        activeLabel: element.title,
      });
    });
    return ret;
  }

  onChangeMediaScope(infos) {
    if (infos.datasource_id === null) {
      this.setState({
        media: false,
      });
      this.fetchInitial(false);
    } else {
      this.setState({
        chatGpt: {
          ...this.state.mediaChatGpt[infos.datasource_id],
        },
        score: {
          ...this.state.mediaStats[infos.datasource_id],
        },
        media: true,
        mediaDatasourceId: infos.datasource_id,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { chatGpt, print } = this.state;

    let page_title = this.pageTitleHandler();

    if (this.state.isLoaded) {
      const project = this.state.project;

      if (this.state.displayNotFound) {
        return <NotFoundView />;
      }

      const sortedChatGptReports = chatGpt.reports.sort(
        (a, b) => a.display_order - b.display_order
      );

      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <div
            id="projectNewInsight"
            className={`responsive-920-padding-top-20`}
            style={{ zIndex: "1", marginTop: "76px" }}
          >
            <div className="central-content" id="scrollArea">
              <div className="position-relative content-title-project display-flex align-items-center justify-content-space-between">
                <div className="position-relative" style={{ width: "100%" }}>
                  <div className="font-size-30 font-inter-700 color-000 column-percent-95 text-break-line">
                    {project.name}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#7f7f7f",
                    marginTop: "15px",
                    alignSelf: "flex-end",
                    textAlign: "right",
                    width: "700px",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "6px",
                    justifyContent: "flex-end",
                  }}
                >
                  Powered by{" "}
                  <img
                    onClick={() => this.props.history.push(ROUTES.HOME)}
                    style={{ width: "25px", height: "20px", cursor: "pointer" }}
                    src={sensiaLogo}
                    alt=""
                  />
                </div>
              </div>

              {this.state.datasourcesList && this.state.totalItems > 0 && (
                <ListOptSelect
                  displayFamily="datasource_id"
                  customClass="big-blue inDepthSelect"
                  defaultActive={this.state.datasourceId}
                  position="left"
                  onChange={this.onChangeMediaScope}
                  lists={this.getMediaScopeList()}
                />
              )}

              <div id="insights-score">
                {this.state.totalItems > 0 && (
                  <Score
                    listItem={false}
                    style={{
                      marginRight: "10px",
                      marginTop: "30px",
                    }}
                    sources={this.state.totalItems}
                    reviews={this.state.score.total_obs_used}
                    total_reviews={this.state.score.total_obs}
                    relevantReviews={
                      this.state.score.total_obs_used &&
                      this.state.score.total_obs
                        ? Number(
                            this.state.score.total_obs_used /
                              this.state.score.total_obs
                          ).toLocaleString("en-US", {
                            style: "percent",
                            minimumFractionDigits: 0,
                          })
                        : "0%"
                    }
                    positive={
                      this.state.score.pos !== null &&
                      this.state.score.pos !== undefined
                        ? Number(this.state.score.pos).toLocaleString("en-US", {
                            style: "percent",
                            minimumFractionDigits: 0,
                          })
                        : "0%"
                    }
                    negative={
                      this.state.score.neg !== null &&
                      this.state.score.neg !== undefined
                        ? Number(this.state.score.neg).toLocaleString("en-US", {
                            style: "percent",
                            minimumFractionDigits: 0,
                          })
                        : "0%"
                    }
                    score={this.state.score.score}
                    feeling={this.state.score.feeling}
                    projectId={this.state.projectId}
                    datasourceId={this.state.datasourceId}
                    sourceTooltip={this.state.datasourceList}
                    updated_at={this.state.score.updated_at}
                    media={this.state.media}
                  />
                )}
              </div>

              {this.state.totalItems > 0 && (
                <div id="main-content">
                  {!print && (
                    <div id="menu">
                      <ul>
                        {sortedChatGptReports?.map((report, index) => {
                          return (
                            <li
                              onClick={() =>
                                this.selectAnchor(
                                  report.title.split(" ").join("_")
                                )
                              }
                              id={`menu_${report.title.split(" ").join("_")}`}
                              className={`anchor ${index == 0 ? "active" : ""}`}
                            >
                              <p>{report.title}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  <div
                    id="text"
                    style={print ? { border: "none", paddingLeft: "0" } : {}}
                  >
                    {sortedChatGptReports?.map((report, index) => {
                      return (
                        <section
                          style={
                            !print ? {} : { fontSize: "11px", width: "700px" }
                          }
                          className="report-section"
                          id={report.title.split(" ").join("_")}
                          menu={report.title.split(" ").join("_")}
                        >
                          <div>
                            {report.sections?.map((section, index) => {
                              return <div key={index}>{parse(section)}</div>;
                            })}
                          </div>
                        </section>
                      );
                    })}
                    <p
                      style={
                        !print
                          ? {}
                          : {
                              fontSize: "9px",
                              width: "700px",
                              alignSelf: "flex-start",
                            }
                      }
                      id="lastUpdate"
                    >
                      Last updated{" "}
                      {moment.utc(chatGpt.updated_at).local().format("L")}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={true}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(ProjectDetailView));
