import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { globalConstants } from "../../app/constants";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import LoadingView from "../loading/LoadingView";
import { ROUTES } from "../../app/routing";
import PlanPricingBody from "../../components/planPricingBody/PlanPricingBody";

class PlanPricingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item_list: [],
      isLoaded: false,
    };
    this.fetchPlan = this.fetchPlan.bind(this);
  }
  componentDidMount() {
    this._ismounted = true;
    this.fetchPlan();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  async fetchPlan() {
    const url = `${globalConstants.API_URL}`;
    const finalUrl = url + ROUTES.PLAN_PRICING_WS;

    fetch(finalUrl)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ item_list: data.plans, isLoaded: true });
      });
  }

  render() {
    const { t } = this.props;
    const page_title = `${t("Pricing")} - ${globalConstants.WEBSITE_TITLE}`;
    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <PlanPricingBody item_list={this.state.item_list} />
        </Fragment>
      );
    } else {
      return (
        <LoadingView
          title={page_title}
          withHeader={true}
          headerProps={{ isProject: true }}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(PlanPricingView));
