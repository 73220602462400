import React, { Fragment } from 'react';
import './errors.css';
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { globalConstants } from '../../app/constants';
import Lottie from 'react-lottie-player';

import catLottieJson from '../../res/lotties/cat.json';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../app/routing';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

class Error500View extends React.Component {

    render() {
        const { t } = this.props;
        let page_title = `${t('Internal Server Error')} - ${globalConstants.WEBSITE_TITLE}`;
        return (
            <Fragment>
                <Helmet>
                    <title>{page_title}</title>
                </Helmet>
                <div className="page-not-found content-error-to-center">
                    <Header withoutFake={true} />
                    <div className="centered">
                        <Lottie
                            loop
                            animationData={catLottieJson}
                            play
                            style={{ width: 300, height: 300, margin: "0 auto" }}
                        />
                        <div className="padding-top-0 font-size-60 font-inter-700 color-000 line-height-1">{t('500')}</div>
                        <div className="font-size-36 font-inter-400 color-000">{t('Internal Server Error')}</div>
                        <div className="padding-top-5 font-size-16 font-inter-300 color-000">{t('Arf, the page you are looking seems to be broken!')}</div>
                        <div className="padding-top-20">
                            <Link to={ROUTES.HOME} className="button height-48">{t('Return to homepage')}</Link>
                        </div>
                    </div>
                    <Footer withoutFake={true}/>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(Error500View);