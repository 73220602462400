import React, { Fragment } from 'react';
import './footer.css';
import { withTranslation } from 'react-i18next';
import logoWhite from '../../res/images/logo/logo-white.svg'
import { ROUTES } from '../../app/routing';
import { Link } from 'react-router-dom';
import { globalConstants } from '../../app/constants';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
            counterK: 0
        };
        this.contentFooter = this.contentFooter.bind(this);
    }

    contentFooter() {
        const { t, isArticle } = this.props;
        return (
            <div className="central-content-1200">
                <div className="content-logo-socials flex-footer">
                    <div id="branding-bottom">
                        <Link className="link" to={ROUTES.HOME}>
                            <img className="" src={logoWhite} />
                        </Link>
                    </div>
                    {!isArticle && <div className="content-menu-and-copyright">
                        <ul>
                            <li>
                                <a href={`${globalConstants.CLIENT_URL}${ROUTES.TERMS_AND_CONDITIONS}`}>{t('Terms of service')}</a>
                            </li>
                            <li>
                                <a href={`${globalConstants.CLIENT_URL}${ROUTES.PRIVACY_POLICY}`}>{t('Privacy policy')}</a>
                            </li>
                            <li>
                                <a href={`${globalConstants.CONTACT_URL}`}>{t('Contact')}</a>
                            </li>
                        </ul>
                    </div>}
                    <div className="content-socials">
                        <a href="https://www.linkedin.com/company/sensia-ai/" rel="noreferrer" target="_blank"
                            className="social linkedin"><span></span></a>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        const { withoutFake } = this.props;
        return (
            <Fragment>
                {!withoutFake && <div className="fake footer-showcase">
                    {this.contentFooter()}
                </div>}
                <div className="footer-showcase">
                    {this.contentFooter()}
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(Footer);